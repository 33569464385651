import React from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import { AppProvider, FormBuilderProvider } from "context";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import i18n from "./i18n";
import "./static/css/index.css";

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <I18nextProvider i18n={i18n}>
        <FormBuilderProvider>
          <AppProvider>
            <App />
          </AppProvider>
        </FormBuilderProvider>
      </I18nextProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
