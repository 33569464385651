import React, { useEffect, useState } from "react";

import { wrapper } from "core";
import { Typo, Button } from "components";
import { numberFormat } from "core/helpers/number";

type InputValueType = number | string;
interface ICustomInput
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  suffix: string;
  onChange: (name: string, value: InputValueType) => void;
}

const CustomInput = ({ suffix, onChange, step, ...props }: ICustomInput) => {
  const [value, setValue] = useState<InputValueType>("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue: InputValueType = step
      ? e.target.valueAsNumber
      : parseInt(e.target.value);

    if (newValue > -1 && typeof step === "number") {
      newValue = parseFloat(newValue.toFixed(step));
    } else if (isNaN(newValue)) newValue = "";

    setValue(newValue);
    onChange(e.target.name, newValue);
  };
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key == "-") {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
  };

  return (
    <div className="relative">
      <input
        {...props}
        step={step}
        value={value}
        onChange={handleChange}
        onKeyDown={onKeyDown}
      />
      <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
        <span className="text-black opacity-20 sm:text-sm">{suffix}</span>
      </div>
    </div>
  );
};

const useDecrireSituation = ({ category, updateTotal }: any) => {
  const [state, setState] = React.useState(category.defaultState || {});

  const onChange: ICustomInput["onChange"] = (name, value) => {
    setState({ ...state, [name]: value });
  };

  useEffect(() => {
    setState(category.defaultState || {});
  }, [category.id]);

  const _value = Object.keys(state).reduce(
    (sum, key) => sum * parseFloat(state[key] || 0),
    1
  );

  useEffect(() => {
    if (!_value) return;
    const v = _value || 0;

    updateTotal(v);
  }, [_value]);

  const handleNext = (e: any) => {
    wrapper.setTcVars({
      evt_page_nom: "SAC_revenu_assurable",
      evt_montant_projet: _value,
    });
    wrapper.triggerEvent("pageVirtuelle", e.currentTarget, {
      evt_page_nom: "SAC_revenu_assurable",
      evt_montant_projet: _value,
    });
  };

  return {
    value: _value,
    onChange,
    handleNext,
  };
};

export const DecrireSituation = ({
  category,
  updateTotal,
  handleToggleState,
  handleSimulation,
  toggleState,
}: any) => {
  const { value, onChange, handleNext } = useDecrireSituation({
    category,
    updateTotal,
  });

  const {
    id,
    description,
    example,
    surface,
    rendementMoyen,
    prixMoyen,
    capital,
    _surface,
    _rendementMoyen,
    _prixMoyen,
    _capital,
  } = category;

  return (
    <div>
      {id !== 0 && (
        <div className="font-openSansBold bg-slate-200 px-8 pb-8">
          <Typo
            variant="h5"
            children="personnalisez vos informations"
            className="uppercase text-primary"
            noMargin
          />
          <Typo variant="h5" className="uppercase text-primary mb-8">
            {" "}
            {description} <span className="lowercase">{example}</span>{" "}
          </Typo>
          <div className="flex flex-col sm:flex-row gap-10 justify-between md:pr-28 md:pl-6 describe">
            {id !== 4 ? (
              <div>
                {" "}
                <div className="flex-1">
                  <div className="relative flex flex-col md:flex-row justify-between  md:items-center mb-4">
                    <Typo
                      variant="h5"
                      children="Surface de cette culture :"
                      className="text-primary-600 sm:text-14  mb-4 md:mb-0"
                    />
                    <CustomInput
                      type="number"
                      name="surface"
                      step={1}
                      min={0}
                      className="h-_30 sm:w-_150 rounded-3 ml-4"
                      onChange={onChange}
                      placeholder={surface}
                      suffix={_surface}
                    />
                  </div>
                  <div className="relative flex flex-col md:flex-row justify-between  md:items-center mb-4">
                    <Typo
                      variant="h5"
                      children="Rendement moyen :"
                      className="text-primary-600 sm:text-14 mb-4 md:mb-0"
                    />
                    <CustomInput
                      type="number"
                      name="rendementMoyen"
                      step={1}
                      min={0}
                      className="h-_30 sm:w-_150 rounded-3 ml-4"
                      onChange={onChange}
                      placeholder={rendementMoyen}
                      suffix={_rendementMoyen}
                    />
                  </div>
                </div>
                <div className="relative flex flex-col md:flex-row justify-between  md:items-center">
                  <Typo
                    variant="h5"
                    children="Prix de vente moyen :"
                    className="text-primary-600 sm:text-14 mb-4 md:mb-0"
                  />
                  <CustomInput
                    type="number"
                    name="prixMoyen"
                    className="h-_30 sm:w-_150 rounded-3 ml-4"
                    min={0}
                    onChange={onChange}
                    placeholder={prixMoyen}
                    suffix={_prixMoyen}
                  />
                </div>
              </div>
            ) : (
              <div className="flex flex-col justify-between">
                <div className="relative flex flex-col md:flex-row justify-between  md:items-center">
                  <Typo
                    variant="h5"
                    children="Surface de cette culture :"
                    className="text-primary-600 sm:text-14 mb-4 md:mb-0"
                  />
                  <CustomInput
                    type="number"
                    name="surface"
                    className="h-_30 sm:w-_150 rounded-3 mb-4 ml-4 sm:mb-0"
                    onChange={onChange}
                    placeholder={surface}
                    suffix={_surface}
                    min={0}
                    max={99999}
                  />
                </div>
                <div className="relative flex flex-col md:flex-row justify-between  md:items-center">
                  <Typo
                    variant="h5"
                    children="Capital à assurer:"
                    className="text-primary-600 sm:text-14 mb-4 md:mb-0"
                  />
                  <CustomInput
                    type="number"
                    name="capital"
                    className="h-_30 sm:w-_150 rounded-3 ml-4"
                    onChange={onChange}
                    placeholder={capital}
                    suffix={_capital}
                    min={0}
                    max={99999}
                  />
                </div>
              </div>
            )}
            <div className="hidden sm:block bg-gray-700 w-_2 relative arrow-right"></div>
            <div className="sm:w-_208 border-primary-400 rounded-3 bg-white p-2 border-1">
              <Typo
                children="Revenu assurable :"
                className="text-primary-600 sm:text-14 text-center mt-4"
              />
              <Typo
                variant="h3"
                children={numberFormat(Math.round(value))}
                className="text-black  mt-4 opacity-20 sm:text-25 text-center"
              />
            </div>
          </div>
        </div>
      )}
      <div className="bg-slate-200">
        {category.id !== 0 && toggleState === 1 && (
          <Button
            children={"Page suivante"}
            className="m-auto transform transition duration-500 hover:bg-primary-800 hover:scale-110 border-0 rounded-sm"
            onClick={(e) => {
              handleNext(e);
              toggleState === 1 ? handleToggleState() : handleSimulation();
            }}
          />
        )}
      </div>
    </div>
  );
};
