import { overrideTailwindClasses } from "tailwind-override";

export const ucFirst = (name = "", restLowercase = false) =>
  name
    ? name.charAt(0).toUpperCase() +
      (restLowercase ? name.slice(1).toLocaleLowerCase() : name.slice(1))
    : "";
export const pluralize = (name: string, count?: number, suffix = "s") =>
  `${name}${count === undefined || count > 1 ? suffix : ""}`;
export const dashed = (name: string) => name?.split(" ").join("-");
export const unDashed = (name: string) => name?.split("-").join(" ");

export const truncate = (str: string, nb: number) => {
  if (!str) return "";
  const arr = str?.split(" ");
  const truc = arr.slice(0, nb) || [];

  return truc.join(" ")?.concat(arr.length > nb ? "..." : "");
};
export const toHex = (num: number): string => {
  const val = Number(num);
  return "0x" + val.toString(16);
};

export const clsx = (...classNamesString: (string | boolean | undefined)[]) =>
  overrideTailwindClasses(classNamesString.filter(Boolean).join(" "));
