export const isArray = (x: unknown): x is [] => Array.isArray(x);
export const isStr = (x: unknown): x is string => typeof x === "string";
// eslint-disable-next-line @typescript-eslint/ban-types
export const isFn = (x: any): x is Function => x && typeof x === "function";
export const isObj = (x: unknown): x is object =>
  Object.prototype.toString.call(x) === "[object Object]";
export const isDate = (x: unknown): x is Date =>
  Object.prototype.toString.call(x) === "[object Date]";
export const isBool = (x: any): x is boolean => x && typeof x == "boolean";

export const isEmpty = (x: unknown): x is object | [] | string => {
  if (isObj(x)) return !Object.keys(x).length;
  if (isArray(x) || isStr(x)) return !x?.length;
  return true;
};
