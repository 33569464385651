import { config } from "config";
import TC_Wrapper from "react-tag-commander";
const tc_wrapper = TC_Wrapper.getInstance();

export const pageName = {};
export const wrapper = config.disableTransition
  ? {
      getInstance: (...args: any) => console.log({ getInstance: args }),
      setTcVar: (...args: any) => console.log({ setTcVar: args }),
      setTcVars: (...args: any) => console.log({ setTcVars: args }),
      triggerEvent: (...args: any) => console.log({ triggerEvent: args }),
    }
  : tc_wrapper;
