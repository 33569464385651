import { useState } from "react";
import { Pile, Icon, Typo, Button } from "components";
import { numberFormat } from "core/helpers/number";
import { wrapper } from "core";

export const Resultat = ({
  seuilDePerte,
  niveauDeFranchise,
  category,
}: any) => {
  const [info, setInfo] = useState(0);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(0);

  const sinisterTotal = (category.total * seuilDePerte) / 100;
  const handleRv = (e: any) => {
    wrapper.setTcVars({
      evt_action_libelle: "SAC_prendre_rdv",
    });
    wrapper.triggerEvent("actionUtilisateur", e.currentTarget, {
      evt_action_libelle: "SAC_prendre_rdv",
    });
  };

  return (
    <div>
      <div className="px-10 bg-slate-200 pt-8 mt-1">
        <div className="flex flex-col md:flex-row justify-between gap-16">
          <div className="tex-center flex-1">
            <div className="bg-primary-400 w-full h-16 rounded-3 text-center text-white text-14 flex justify-center items-center uppercase">
              <p className="text-center text-22">
                Je ne prends pas d'assurance
              </p>
            </div>
            <div className="mt-8">
              <div className="flex flex-col sm:flex-row items-center justify-center">
                <p className="uppercase text-primary-600 text-16 mr-2">
                  seuil de pertes prédéfini par l’état :{" "}
                  <span className="text-primary-400">
                    {`${category.jauge}%`}
                  </span>
                </p>
              </div>
              <div className="mt-8 flex justify-between items-center">
                <Pile
                  jauge={category.jauge}
                  category={category}
                  sinisterTotal={sinisterTotal}
                  seuilDePerte={seuilDePerte}
                  updateState={setStart}
                />
              </div>
            </div>
          </div>
          <div className="flex-1">
            <div className="bg-primary w-full h-16 rounded-3 text-center text-white text-14 flex justify-center items-center uppercase">
              <p className="text-22">Je prends une assurance</p>
            </div>
            <div className="mt-8">
              <div className="flex flex-col sm:flex-row items-center justify-center">
                <p className="uppercase text-primary-600 text-16 mr-2">
                  niveau de franchise choisie :{" "}
                  <span className="text-primary font-openSansExtraBold">
                    {niveauDeFranchise}%
                  </span>
                </p>
                <div className="relative">
                  <div onClick={() => setInfo(4)}>
                    <Icon
                      type="info-circle"
                      className="fill-primary-800 hover:cursor-pointer"
                    />
                  </div>
                  {info === 4 ? (
                    <div className="absolute bg-gray-1100 font-openSansBold  sm:w-_200 sm:-left-_187 z-10  top-7 pt-_3 pl-_5 pr-_10 pb-2">
                      <p className="text-white  font-openSansRegular sm:text-10 text-left">
                        L'assurance Récoltes améliore considérablement votre
                        protection. <br /> Elle reste subventionnée par l'État.
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="mt-8 flex">
                <Pile
                  isAssurance
                  jauge={niveauDeFranchise}
                  category={category}
                  sinisterTotal={sinisterTotal}
                  seuilDePerte={seuilDePerte}
                  updateState={setEnd}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="pb-8">
          <div className="w-full mt-8 bg-white border-l-4 border-red-500 rounded-3 pl-8 pr-4 py-4">
            {seuilDePerte <= niveauDeFranchise ? (
              <p className="font-openSansRegular text-14">
                Votre perte est inférieure ou égale à la franchise de {""}
                {niveauDeFranchise}% que vous avez choisie :{" "}
                <span className="font-openSansExtraBold">
                  avec ou sans assurance, votre indemnisation est de 0 €.
                </span>
              </p>
            ) : (
              <p className="font-openSansRegular text-14">
                {category.id <= 2
                  ? "Si vous choisissez une assurances Récoltes / Prairies en "
                  : "Si vous complétez le Fonds de Solidarité Nationale par une assurance récoltes avec "}
                franchise {niveauDeFranchise}%,{" "}
                <span className="font-openSansExtraBold">
                  votre indemnisation passe de {numberFormat(start)} sans
                  assurance à {numberFormat(end)} pour un sinistre de{" "}
                  {numberFormat(sinisterTotal)}.
                </span>
              </p>
            )}
          </div>
        </div>
        {info !== 0 ? (
          <div className="close" onClick={() => setInfo(0)}></div>
        ) : (
          ""
        )}
      </div>
      <div className="mt-8 flex flex-col md:flex-row justify-between items-center gap-8">
        <Typo variant="body2" className=" text-black">
          Pour en savoir plus et estimer le coût des cotisations d’assurances
          Récoltes / Prairies, prenez rendez-vous avec un conseiller du Crédit
          Agricole.
        </Typo>
        <Button
          to="https://www.credit-agricole.fr/agriculteur/assurances/demande-de-rdv-reforme-assurance-recoltes.html"
          target="_blank"
          children="Je demande un rendez-vous"
          className="p-5 first-line:uppercase transform transition duration-500 hover:bg-primary-800 hover:scale-110 border-0 rounded-sm"
          onClick={(e: any) => handleRv(e)}
        />
      </div>
    </div>
  );
};
