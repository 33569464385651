import { MenuLinkType } from "core/helpers";
import { getMenuLinks } from "core/helpers/navigation";
import { createContext, useContext, useEffect, useState } from "react";
import { DrawerOptions } from "components";
import { BannerPropsType } from "components";
import { useTranslation } from "react-i18next";
import { routeList } from "routing/routeList";

export type AppContextType = {
  mode: "tenant" | "admin";
  setMode: (mode: "tenant" | "admin") => void;

  bannerProps: BannerPropsType;
  setBannerProps?: (props: BannerPropsType) => void;

  menuLinks: Array<MenuLinkType>;

  mainModalOptions: DrawerOptions;
  setMainModalOptions: (opts: DrawerOptions) => void;

  //   mainSlideOverOptions: DrawerOptions;
  //   setMainSlideOverOptions: (opts: DrawerOptions) => void;

  currentLang: string;
  setCurrentLang: (lang: string) => void;
};

export const AppContext = createContext<AppContextType>({
  mode: "tenant",
} as any);

export function AppProvider({ children, defaultLanguage = "en" }: any) {
  const { i18n } = useTranslation();

  const [mode, setMode] = useState("tenant");
  const [bannerProps, setBannerProps] = useState<BannerPropsType>({});
  const [mainModalOptions, setMainModalOptions] = useState<DrawerOptions>();
  const [mainSlideOverOptions, setMainSlideOverOptions] =
    useState<DrawerOptions>();
  const [currentLang, setCurrentLang] = useState(
    localStorage.getItem("lang") ?? defaultLanguage
  );

  const menuLinks = getMenuLinks(routeList, mode as any);

  useEffect(() => {
    localStorage.setItem("lang", currentLang);
    i18n.changeLanguage(currentLang);
  }, [currentLang, i18n]);

  return (
    <AppContext.Provider
      value={
        {
          mode,
          setMode,
          menuLinks,
          mainSlideOverOptions,
          setMainSlideOverOptions,
          bannerProps,
          setBannerProps,
          mainModalOptions,
          setMainModalOptions,
          currentLang,
          setCurrentLang,
        } as any
      }
    >
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext(): AppContextType {
  return useContext(AppContext);
}
