import { Dispatch, SetStateAction, useState } from "react";
import { DecrireSituation } from "./decrireSituation";
import { CompareProtection } from "./compareProtection";
import { Secteurs, wrapper } from "core";
import { MentionsLegales } from "components/ui/Simulation/MentionsLegales";

const Simulation = ({
  updateTotal,
  toggleState,
  setToggleState,
  category,
}: {
  updateTotal: (total: number) => void;
  toggleState: number;
  setToggleState: Dispatch<SetStateAction<number>>;
  category: Secteurs;
}) => {
  const [toggleResultat, setToggleResultat] = useState(false);

  const handleSimulation = () => {
    setToggleResultat(true);
  };
  const handleToggleState = () => {
    wrapper.setTcVars({
      evt_action_libelle: "SAC_simuler_perte",
    });
    setToggleState(2);
  };

  return (
    <div className="font-openSansBold">
      {toggleState === 1 ? (
        <DecrireSituation
          category={category}
          updateTotal={updateTotal}
          toggleState={toggleState}
          handleToggleState={handleToggleState}
          handleSimulation={handleSimulation}
        />
      ) : (
        <CompareProtection
          category={category}
          toggleResultat={toggleResultat}
          setToggleResultat={setToggleResultat}
        />
      )}
      <MentionsLegales
        variant={toggleResultat === false ? "primary" : "secondary"}
      />
    </div>
  );
};

export default Simulation;
