import { useState, useEffect } from "react";
import { clsx, secteurs, Secteurs, wrapper } from "core";

import Simulation from "../simulation";
import { Typo } from "components/ui/Typo";

export const AssuranceEmprunteur = () => {
  const [toggleState, setToggleState] = useState(1);
  const [category, setCategory] = useState<Secteurs>({
    id: 0,
    type: "",
    icon: "",
    description: "",
    surface: "",
    rendementMoyen: "",
    prixMoyen: "",
    jauge: 0,
    total: 0,
    capital: "",
    defaultState: {},
    niveauxFranchise: [],
  });
  const updateTotal = (total: number) => setCategory({ ...category, total });
  useEffect(() => {
    wrapper.setTcVars({
      page_nom: "SAC_situation",
    });
    wrapper.triggerEvent("chargementPage", {
      evt_page_nom: "SAC_situation",
    });
  }, []);

  const handleSetCategory = (e: any, { id, icon, type, ...props }: any) => {
    setCategory({ id, icon, type, ...props });
    wrapper.setTcVars({
      evt_action_libelle: "SAC_detail_situation",
      evt_simul_option: type,
    });

    wrapper.triggerEvent("actionUtilisateur", e.currentTarget, {
      evt_action_libelle: "SAC_detail_situation",
      evt_simul_option: type,
    });
  };

  return (
    <div>
      <div className="flex flex-col gap-1 mb-4 font-openSansSemiBold">
        <p className="text-sm">
          Votre dispositif de gestion des risques climatiques en agriculture en
          2024
        </p>
        <Typo
          variant="h2"
          className="mb-2 uppercase text-primary sm:text-22 font-openSansBold"
          children="QUELLE SERAIT VOTRE INDEMNITÉ SUITE À UN ÉVÉNEMENT CLIMATIQUE ?"
        />
        <h2>
          Souscrire une assurance Récoltes et/ou Prairies améliore
          considérablement votre niveau de protection. Vérifiez-le immédiatement
          !
        </h2>
      </div>
      <div className="sm:w-_400 flex">
        <button
          className={clsx(
            "text-white flex-1 py-3 bg-primary uppercase font-openSansBold relative",
            toggleState === 1 ? "arrow-bottom bg-primary-600" : "bg-primary"
          )}
          onClick={() => setToggleState(1)}
        >
          Étape 1 : votre situation
        </button>
        <button
          className={clsx(
            "text-white flex-1 py-3 uppercase font-openSansBold relative",
            toggleState === 2 ? "arrow-bottom bg-primary-600" : "bg-primary"
          )}
        >
          Étape 2 : estimations D'indemnités
        </button>
      </div>

      <div
        className={clsx(
          "w-full bg-slate-200 mt-3 pl-8  pt-10 relative",
          category.id === 0 ? "p-10  md:pr-20" : "pr-10"
        )}
      >
        {toggleState === 1 ? (
          <h2 className="text-16 font-openSansSemiBold  text-primary uppercase">
            Que produisez-Vous<span className="text-red-600">*</span> ?
          </h2>
        ) : (
          <div className="hidden sm:block">
            <div className="absolute top-0 -translate-y-full flex bg-primary-600 text-14 font-openSansBold text-white right-8 p-2 rounded-3">
              <div
                className="py-2 h-8 w-8 bg-no-repeat bg-contain bg-center"
                style={{ backgroundImage: `url(svg/${category.icon}.svg)` }}
              />
              <span className="p-2">{category.type}</span>
            </div>
          </div>
        )}
        {toggleState === 1 && (
          <div className="md:flex justify-between text-14 font-openSansBold text-primary-600 md:mt-5  md:pl-6 md:pr-10 pb-8">
            {secteurs.map(({ id, icon, type, ...props }) => (
              <div
                className={clsx(
                  "flex justify-center items-center gap-2 hover:cursor-pointer border-primary-400 py-2 md:py-0 rounded-3 pl-2 pr-4 border-1 sm:text-14",
                  category.id === id ? "bg-primary-600 text-white" : "bg-white"
                )}
                key={id}
                onClick={(e) =>
                  handleSetCategory(e, { id, icon, type, ...props })
                }
              >
                <div
                  className="py-2 h-8 w-8 bg-no-repeat bg-contain bg-center"
                  style={{ backgroundImage: `url(svg/${icon}.svg)` }}
                />
                <span className="p-2">{type}</span>
              </div>
            ))}
          </div>
        )}
      </div>
      <Simulation
        updateTotal={updateTotal}
        toggleState={toggleState}
        setToggleState={setToggleState}
        category={category}
      />
    </div>
  );
};
