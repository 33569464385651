import { isObj } from "core/helpers/check";
import CryptoJS from "crypto-js";

export default class SecureStorageService {
  static key = "this is the key what encrypt content";

  static setItem(name: string, value: string, isSesssionStorage = false) {
    if (isSesssionStorage)
      sessionStorage.setItem(
        name,
        this.encrypt(isObj(value) ? JSON.stringify(value) : value)
      );
    else
      localStorage.setItem(
        name,
        this.encrypt(isObj(value) ? JSON.stringify(value) : value)
      );
  }

  static getItem(name: string): string | unknown {
    let value: string | null =
      localStorage.getItem(name) || sessionStorage.getItem(name);

    try {
      value = value != null ? this.decrypt(value) : value;
      return JSON.parse(value as string);
    } catch (e) {
      return value;
    }
  }

  static encrypt(value: string): string {
    return CryptoJS.AES.encrypt(value, this.key).toString();
  }

  static decrypt(encrypted: string): string {
    return CryptoJS.AES.decrypt(encrypted, this.key).toString(
      CryptoJS.enc.Utf8
    );
  }
}
