import { createContext, useContext, FC } from "react";
import { FormFieldsVariant } from "@types";
import { TypoTypeEnum } from "components";

type ClassNameTypes = {
  typo?: {
    [key in TypoTypeEnum]?: string;
  };
  form?: {
    className: string | undefined;
  };
  input?: {
    wrapper?: string | undefined;
    container?: string | undefined;
    className?: string | undefined;
  };
  checkbox?: {
    wrapper?: string | undefined;
    checkboxContainer?: string | undefined;
    checkbox?: string | undefined;
    labelContainer?: string | undefined;
    label?: string | undefined;
  };
  label?: {
    className: string | undefined;
  };
  legend?: {
    className: string | undefined;
  };
  action?: {
    className: string | undefined;
  };
};
interface FormBuilderProviderProps {
  variant?: FormFieldsVariant;
  classNames?: ClassNameTypes;
}
const FormBuilderContext = createContext<FormBuilderProviderProps>({});

export const FormBuilderProvider: FC<FormBuilderProviderProps> = ({
  variant = "standard",
  children,
  classNames,
}) => {
  return (
    <FormBuilderContext.Provider
      value={{
        variant,
        classNames: {
          typo: {
            h1: classNames?.typo?.h1,
            h2: classNames?.typo?.h2,
            h3: classNames?.typo?.h3,
            h4: classNames?.typo?.h4,
            body1: classNames?.typo?.body1,
            body2: classNames?.typo?.body2,
          },
          form: {
            className: classNames?.form?.className || "",
          },
          input: {
            wrapper: classNames?.input?.wrapper || "",
            container: classNames?.input?.container || "",
            className: classNames?.input?.className || "",
          },
          label: {
            className: classNames?.label?.className || "",
          },
          legend: {
            className: classNames?.legend?.className || "",
          },
          action: {
            className: classNames?.action?.className || "",
          },
        },
      }}
    >
      {children}
    </FormBuilderContext.Provider>
  );
};

export const useFormBuilder = () => useContext(FormBuilderContext);
