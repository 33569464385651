import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Typo } from "components/ui/Typo";
import { XIcon } from "@heroicons/react/outline";

export type DrawerOptions = {
  width?: any;
  title?: string;
  content?: any;
  isOpen?: boolean;
};

export const Drawer = {
  SlideOver,
  Modal,
};

type DrawerPropsType = {
  drawerOptions: DrawerOptions;
  setDrawerOptions: (opts: DrawerOptions) => void;
  [name: string]: any;
};

function SlideOver({ drawerOptions }: DrawerPropsType) {
  return (
    <Transition.Root show={drawerOptions.isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={console.log}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="pointer-events-auto w-screen max-w-md">
                <div className="flex h-full flex-col overflow-y-scroll bg-dark py-6 shadow-xl">
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <Dialog.Title className="text-lg font-medium text-gray-900">
                        {" "}
                        Panel title{" "}
                      </Dialog.Title>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className="rounded-md bg-dark text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          onClick={console.log}
                        >
                          <span className="sr-only">Close panel</span>
                          {/* <XIcon className="h-6 w-6" aria-hidden="true" /> */}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="relative mt-6 flex-1 px-4 sm:px-6">
                    {/* Replace with your content */}
                    <div className="absolute inset-0 px-4 sm:px-6">
                      <div
                        className="h-full border-2 border-dashed border-gray-200"
                        aria-hidden="true"
                      />
                    </div>
                    {/* /End replace */}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export function Modal({ drawerOptions, setDrawerOptions }: DrawerPropsType) {
  return (
    <Dialog
      as="div"
      className="fixed z-50 inset-0 overflow-y-auto"
      open={drawerOptions.isOpen}
      onClose={() => false}
    >
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:p-0">
        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        <div
          className="relative inline-block align-bottom bg-dark rounded-lg px-4 pt-4 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full sm:p-6"
          style={{ width: drawerOptions.width ?? "50%" }}
        >
          <div className="flex justify-between items-center border-b">
            <Typo variant="h4" noMargin className="text-white">
              {drawerOptions.title}
            </Typo>
            <button
              type="button"
              className="h-fit bg-dark rounded-md text-white hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:invisible"
              onClick={() =>
                setDrawerOptions && setDrawerOptions({ isOpen: false })
              }
            >
              <span className="sr-only">Close</span>
              <XIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-4">{drawerOptions.content}</div>
        </div>
      </div>
    </Dialog>
  );
}
