import { gql, GraphQLClient } from "graphql-request";
import { config } from "config";
import { AuthService } from "core/services";

export const makeGQLQuery = ({ chunks }: { chunks: string }) => {
  return async (): Promise<unknown> => {
    let data;
    if (config.apiUrl) {
      const graphqlClient = new GraphQLClient(`${config.apiUrl}/graphql`, {
        headers: {
          authorization: `Bearer ${AuthService.getToken()}`,
        },
      });

      data = await graphqlClient.request(
        gql`
          ${chunks}
        `
      );
    }
    return data;
  };
};
