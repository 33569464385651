import { isObj } from "core/helpers/check";
import SecureStorageService from "./SecureStorage";

export class AuthService {
  static rememberMe = false;
  /**
   * Recupere le token
   *
   * @returns {string}
   */
  static getToken() {
    return SecureStorageService.getItem("token");
  }

  /**
   * Rêcupère l'utilisateur connecté
   *
   * @returns {{id: number, uuid: string, email: string, name: string}} | null
   */
  static getUser<Type>(): Type | null {
    const _user = SecureStorageService.getItem("user");
    if (!isObj(_user)) return null;

    return _user as unknown as Type;
  }

  static setToken(token: string, isSesssionStorage = false) {
    SecureStorageService.setItem("token", token, isSesssionStorage);
  }

  static setUser(auth: object, isSesssionStorage = false) {
    SecureStorageService.setItem(
      "user",
      JSON.stringify(auth),
      isSesssionStorage
    );
  }

  /**
   * Permet de supprimé le token
   *
   * @returns {void}
   */
  static removeToken() {
    localStorage.removeItem("token");
    sessionStorage.removeItem("token");
  }

  /**
   * Supprime l'utilisateur connecté
   *
   * @returns {void}
   */
  static removeUser() {
    localStorage.removeItem("user");
    sessionStorage.removeItem("user");
  }

  /**
   * Determine si un utilisateur est authentifié
   *
   * @returns {boolean}
   */
  static isAuth() {
    return null != AuthService.getToken();
  }

  /**
   * Nettoie tous les informations liee a l'auth
   */
  static localLogout() {
    AuthService.removeToken();
    AuthService.removeUser();
  }
}
