import {
  Children,
  cloneElement,
  FC,
  isValidElement,
  ReactElement,
} from "react";
import { clsx } from "core";
import { Drawer } from "components/ui";
import { useAppContext } from "context/AppContext";

const contentSizeTypes = {
  small: "h-_64",
  medium: "max-w-full",
  large: "w-full",
};

interface Props {
  className?: string;
  withoutBanner?: boolean;
  contentSize?: keyof typeof contentSizeTypes;
}
export const BaseLayout: FC<Props> = ({
  children,
  contentSize = "medium",
  className,
}) => {
  const { setMainModalOptions, mainModalOptions } = useAppContext();

  return (
    <div className="relative">
      <div
        className={clsx(
          "flex justify-center min-h-screen m-auto",
          contentSizeTypes[contentSize],
          className
        )}
      >
        {/* Clone children an pass props */}
        {Children.map(children, (child) =>
          !isValidElement(child)
            ? child
            : cloneElement(child as ReactElement<any>, { setMainModalOptions })
        )}
      </div>
      {mainModalOptions && mainModalOptions.isOpen && (
        <Drawer.Modal
          drawerOptions={mainModalOptions}
          setDrawerOptions={setMainModalOptions}
        />
      )}
    </div>
  );
};
