import React, { FC } from "react";
import { Icon, IconProps } from "components/ui/Icon";
import { clsx } from "core";
import { Link } from "react-router-dom";

const sizes = {
  large: {
    default: "px-12 py-5 rounded-2xl",
    onlyIcon: "p-5 rounded-2xl",
  },
  small: {
    default: "px-8 py-3 rounded-lg text-sm",
    onlyIcon: "p-3 rounded-lg",
  },
  extraSmall: {
    default: "px-6 py-_6 rounded-md text-xs",
    onlyIcon: "p-_6 rounded-md",
  },
};

const variants = {
  primary: "border-2 border-primary bg-primary",
  secondary: "border-2 border-primary",
};
interface ButtonProps {
  icon?: string;
  to?: string;
  target?: "_blank";
  iconPosition?: "left" | "right";
  label?: string;
  disabled?: boolean;
  size?: keyof typeof sizes;
  variant?: keyof typeof variants;
  rounded?: boolean;
  className?: string;
  iconProps?: Omit<IconProps, "type">;
  onClick?: (val: any) => void;
}

const ChildRender = ({
  label,
}: {
  label: string | JSX.IntrinsicAttributes;
}) => <span className="relative block m-auto text-white">{label}</span>;

const WrapperComponent: FC<ButtonProps> = ({
  to,
  target,
  children,
  ...props
}) =>
  to ? (
    target ? (
      <a href={to} target={target} {...props}>
        {children}
      </a>
    ) : (
      <Link to={to} {...props}>
        {children}
      </Link>
    )
  ) : (
    <button {...props}>{children}</button>
  );

export const Button: FC<ButtonProps> = ({
  children,
  icon,
  to,
  target,
  size = "small",
  label,
  variant = "primary",
  rounded,
  onClick,
  disabled,
  className,
  iconPosition,
  iconProps,
}) => (
  <WrapperComponent
    to={to}
    target={target}
    disabled={disabled}
    className={clsx(
      "flex items-center flex-row gap-2 font-medium text-white",
      iconPosition === "right" && "flex-row-reverse",
      size
        ? label || children
          ? sizes[size].default
          : sizes[size].onlyIcon
        : "",
      variant && variants[variant],
      rounded && "rounded-full",
      className
    )}
    onClick={onClick}
  >
    {icon ? <Icon type={icon} {...iconProps} /> : ""}
    {label || (children && <ChildRender label={label || children} />)}
  </WrapperComponent>
);
