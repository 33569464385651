import React from "react";
import { Typo } from "components";
import { Modalites } from "./modalites";

export const Devis = ({ children }: { children: React.ReactElement[] }) => {
  return (
    <div className="flex gap-5 px-2 sm:px-4 pt-4 pb-20">
      <div>
        <div>
          <Typo
            variant="h2"
            children={
              <>
                EN 2024, VOTRE DISPOSITIF RISQUE CLIMATIQUE <br />
                VOUS OFFRE TOUJOURS DEUX CHOIX :
              </>
            }
            className="mb-2 uppercase text-primary sm:text-22 font-openSansBold"
          />
          <Typo
            variant="h3"
            children="Cliquez sur chaque rectangle pour en savoir plus"
            className="sm:text-25 text-primary-400  font-openSansBold"
          />
        </div>
        <Modalites />
        {children}
      </div>
    </div>
  );
};
