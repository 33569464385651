export const removePercent = (price, percent) => {
  const floatPrice = parseFloat(price);
  const floatPercent = parseFloat(percent);

  return !price || !percent
    ? price
    : parseFloat(floatPrice - (floatPrice * floatPercent) / 100);
};

export const calcPercent = (price, percent) => {
  const floatPrice = parseFloat(price);
  const floatPercent = parseFloat(percent);

  return !price || !percent
    ? price
    : parseFloat((floatPrice * floatPercent) / 100);
};
export const randomIntFromInterval = (min = 0, max) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const formatLangConfig = {
  us: {
    name: "en-US",
    style: "currency",
    currency: "USD",
  },
  fr: {
    name: "fr-FR",
    style: "currency",
    currency: "EUR",
  },
};

export const numberFormat = (
  price,
  { lang = "fr", minimumFractionDigits = 0 } = {}
) => {
  const { name, ...props } = formatLangConfig[lang];

  var formatter = new Intl.NumberFormat(name, {
    ...props,

    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  return formatter.format(parseFloat(isNaN(price) || !price ? 0 : price));
};
export const convertDevice = (price, device, tofix = 2) => {
  const result = device ? (price * parseFloat(device)).toFixed(tofix) : price;

  return isNaN(result) ? "" : parseFloat(result);
};
export const spaceCurrency = (x, spacer = " ") =>
  x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, spacer) : x;
export const formateWalletAddress = (address, min, max) => {
  if (!address) return address;

  return `${address.substr(0, min)}...${address.substr(
    address.length - max,
    address.length - 1
  )}`;
};
