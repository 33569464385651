import { FC } from "react";
import { clsx } from "core";
import { useFormBuilder } from "context";
import { useTranslation } from "react-i18next";
import { isStr } from "core/helpers/check";

export type TypoTypeEnum =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "body1"
  | "body2"
  | "button"
  | "caption"
  | "label";

export const typoVariants = {
  h1: ["h1", "font-black text-_28 sm:text-6xl"],
  h2: ["h2", "font-semibold sm:text-5xl"],
  h3: ["h3", "font-semibold sm:text-[36px]"],
  h4: ["h4", "font-semibold sm:text-[28px]"],
  h5: ["h5", "sm:text-[16px]"],
  body1: ["p", "font-semibold sm:text-sm"],
  body2: ["span", "sm:text-sm"],
  button: ["button", "sm:text-sm"],
  caption: ["caption", "sm:text-xs"],
  label: ["label", "sm:text-xs"],
};
const typoColors = {
  primary: "text-primary",
  secondary: "text-secondary",
  tertiary: "text-tertiary",
  white: "text-white",
  black: "text-black",
};
export interface TypoProps {
  variant?: TypoTypeEnum;
  className?: string;
  noMargin?: boolean;
  color?: keyof typeof typoColors;
  [key: string]: any;
}

export const Typo: FC<TypoProps> = ({
  children,
  color,
  noMargin,
  variant = "body1",
  className = "",
  ...props
}) => {
  const { t } = useTranslation();
  const _formBuilderCtx = useFormBuilder();
  const [Component, _class] = typoVariants[
    variant as keyof typeof typoVariants
  ] as [keyof JSX.IntrinsicElements, string];
  const _className = clsx(
    "text-white leading-_110 mb-2",
    noMargin && "mb-0",
    _class,
    color && typoColors[color],
    _formBuilderCtx?.classNames?.typo
      ? _formBuilderCtx?.classNames?.typo[variant]
      : "",
    className
  );

  return (
    <Component {...props} className={_className}>
      {isStr(children) ? t(children) : children}
    </Component>
  );
};
