import { Typo, Button } from "components";

export const ConfirmModal = ({ setDisplaySimulation }: any) => {
  return (
    <div className="text-center">
      <Typo
        variant="h2"
        className="font-openSansRegular sm:text-22 sm:leading-7 text-primary leading-8"
      >
        Cet outil de simulation{" "}
        <strong>
          {" "}
          permet d'estimer les indemnités qui vous seraient versées
        </strong>{" "}
        en cas de pertes de récoltes.
      </Typo>
      <Typo className="font-openSansRegular sm:text-22 sm:leading-7 text-primary">
        {" "}
        Pour estimer vos cotisations d'assurances, vous pourrez par la suite
        prendre rendez-vous avec un conseiller Crédit Agricole.
      </Typo>
      <Button
        children={"Page suivante"}
        className="font-openSansBold m-auto mt-8 transform transition duration-500 hover:bg-primary-800 hover:scale-105 border-0 rounded-sm"
        onClick={() => setDisplaySimulation(true)}
      />
    </div>
  );
};
