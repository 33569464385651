import { BaseLayout } from "components";
import { LandingScreen } from "screens";

export function App() {
  return (
    <div className="App">
      <BaseLayout>
        <LandingScreen />
      </BaseLayout>
    </div>
  );
}
