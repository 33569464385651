import { ReactElement } from "react";
import { UserInterface } from "@types";
import { AuthService } from "core/services";
import { Route, Routes } from "react-router";
import { BrowserRouter, Navigate } from "react-router-dom";
import { isFn } from "./check";

export type RouteType = {
  path: string;
  element?: ReactElement | ((arg: UserInterface) => ReactElement);
  children?: Array<RouteType> | Array<any>;
  auth?: ((user: object) => boolean) | boolean;
  needPermission?: string;

  menuIcon?: any;
  menuVisibility: "always" | "never" | "admin" | "tenant" | Array<string>;
  menuName?: string;
};

export type MenuLinkType = {
  name?: string;
  icon?: any;
  to: string;
};

function getRoutes(routeList: Array<RouteType>, authFallBack: string) {
  return routeList.map(({ auth = true, ...route }) => {
    const user = AuthService.getUser() as UserInterface;

    if (route.element)
      route.element = isFn(route.element) ? route.element(user) : route.element;
    if (route.path !== authFallBack && auth) {
      if (!AuthService.isAuth() || (isFn(auth) && !auth(user)))
        return (
          <Route
            key={route.path}
            path="*"
            element={<Navigate to={authFallBack} replace />}
          />
        );
    }
    if (route.children && route.children.length) {
      return (
        <Route
          key={route.path}
          {...route}
          children={getRoutes(route.children, authFallBack)}
        />
      );
    }

    return <Route key={route.path} {...route} />;
  });
}

export function getMenuLinks(
  routeList: Array<RouteType>,
  visibility: "always" | "never" | "admin" | "tenant" = "always"
): Array<MenuLinkType> {
  return routeList
    .filter(
      (item) =>
        item.menuVisibility.includes(visibility) ||
        item.menuVisibility === "always"
    )
    .map((item) => ({
      name: item.menuName,
      icon: item.menuIcon,
      to: item.path,
    }));
}

export function Navigation({
  routeList,
  authFallBack,
}: {
  routeList: Array<RouteType>;
  authFallBack: string;
}) {
  return (
    <BrowserRouter>
      <Routes>{getRoutes(routeList, authFallBack)}</Routes>
    </BrowserRouter>
  );
}
