import { HomeIcon } from "@heroicons/react/outline";
import { publicRoute } from "routing";
import { RouteType } from "core";
import { LandingScreen } from "screens";
import { BaseLayout } from "components/ux/layouts/BaseLayout";

export const routeList: Array<RouteType> = [
  //    Public routes
  {
    path: publicRoute.home,
    menuName: "Accueil",
    element: (
      <BaseLayout>
        <LandingScreen />
      </BaseLayout>
    ),
    menuVisibility: "always",
    menuIcon: HomeIcon,
    auth: false,
  },
];
export const authFallBack = publicRoute.home;
