import { useState, VFC } from "react";
import { Devis, Button, Assurance, ConfirmModal } from "components";
import { Modal } from "components/ui/Simulation/Modal";

export const LandingScreen: VFC = () => {
  const [displaySimulation, setDisplaySimulation] = useState(false);
  const [open, setOpen] = useState(false);

  return (
    <div className="flex justify-between items-center p-4">
      {!displaySimulation ? (
        <Devis>
          <Button
            onClick={() => setOpen(true)}
            className="m-auto mt-16 px-20 uppercase sm:text-14 font-openSansBold border-0 rounded-3 transform transition duration-500 hover:bg-primary-800 hover:scale-105"
          >
            Je démarre <br />
            la simulation
          </Button>
          <Modal
            children={
              <ConfirmModal setDisplaySimulation={setDisplaySimulation} />
            }
            open={open}
            setOpen={setOpen}
          />
        </Devis>
      ) : (
        <Assurance />
      )}
    </div>
  );
};
