import { ReactSVG } from "react-svg";
import { clsx } from "core/helpers/string";

export const ICON_SIZES = {
  auto: "auto",
  tiny: "10px",
  small: "16px",
  medium: "24px",
  semiLarge: "40px",
  large: "48px",
  nearBig: "52px",
  big: "67px",
};
const ICON_COLORS = {
  primary: "text-primary",
  secondary: "text-secondary",
  inherit: "inherit",
  currentColor: "currentColor",
};

export const IconColorTypes = Object.keys(ICON_COLORS);
export const IconSizeTypes = Object.keys(ICON_SIZES);
const iconAdaptFill: string[] = [];
const iconAdaptStroke: string[] = [];

export interface IconProps {
  type: string;
  color?: keyof typeof ICON_COLORS;
  size?: keyof typeof ICON_SIZES;
  customSize?: { width?: string; height?: string };
  strokeColor?: keyof typeof ICON_COLORS;
  rotate?: number;
  className?: string;
  [name: string]: any;
}
export const Icon = ({
  type,
  color = "currentColor",
  size = "auto",
  customSize,
  strokeColor = "currentColor",
  rotate = 0,
  className,
  ...props
}: IconProps) => {
  const svgSize = ICON_SIZES[size];
  const stroke = ICON_COLORS[strokeColor || color] ?? color;
  const path = `svg/${type}.svg`;

  return (
    <ReactSVG
      {...props}
      fallback={() => (
        <div className="text-red-600">
          <img
            src={path}
            style={{
              width: 20,
              height: 20,
            }}
            alt={type}
          />
        </div>
      )}
      src={path}
      className={clsx(
        "svg-container inline-block",
        iconAdaptFill.includes(type) ? "adaptFillColor" : "",
        iconAdaptStroke.includes(type) ? "adaptStrokeColor" : "",
        stroke,
        className
      )}
      beforeInjection={(svg) => {
        if (svgSize)
          svg.setAttribute("style", `width: ${svgSize}; height: ${svgSize};`);
        else if (customSize)
          svg.setAttribute(
            "style",
            `width: ${customSize.width}; height: ${customSize.height};`
          );
      }}
      wrapper="span"
      style={{
        ...(customSize
          ? customSize
          : size
          ? {
              width: svgSize,
              height: svgSize,
            }
          : {}),
        ...(rotate ? { transform: `rotate(${rotate}deg)` } : {}),
      }}
    />
  );
};
