import { useState, useEffect, Key } from "react";
import { Resultat, Typo } from "components";
import { clsx, errorMessage, wrapper } from "core";
import { Button } from "../Form";

export const CompareProtection = ({
  category,
  setToggleResultat,
  toggleResultat,
}: any) => {
  const [seuilDePerte, setSeuilDePerte] = useState<number>(0);
  const [niveauDeFranchise, setNiveauDeFranchise] = useState<number>(0);
  const [showAssurance, setShowAssurance] = useState<boolean>(false);

  const MAX = 100;
  const getBackgroundSize = () => {
    return {
      backgroundSize: `${(seuilDePerte * 100) / MAX}%`,
    };
  };

  const handleResultat = () =>
    !niveauDeFranchise
      ? window.alert(errorMessage.setFranchise)
      : setToggleResultat(true);
  useEffect(() => {
    wrapper.setTcVar("page_nom", "SAC_estimation_indemnites");
    wrapper.triggerEvent("chargementPage", {
      page_nom: "SAC_estimation_indemnites",
    });
  }, []);
  const handleNiveauDeFranchise = (e: any, percent: number) => {
    setNiveauDeFranchise(percent);
    wrapper.setTcVars({
      evt_action_libelle: "SAC_selection_quotite",
      evt_assurance: percent,
    });
    wrapper.triggerEvent("actionUtilisateur", e.currentTarget, {
      evt_action_libelle: "SAC_selection_quotite",
      evt_assurance: percent,
    });
  };

  return (
    <div>
      <div>
        <div className="px-4 pb-4 md:px-20 bg-slate-200">
          <h2 className="text-16 font-openSansSemiBold  text-primary uppercase">
            Un événement climatique survient. simulez votre niveau de perte
            <span className="text-red-600">*</span> :{" "}
            <span className="text-red-600">{seuilDePerte}%</span>
          </h2>
          <div
            className={clsx("flex w-full my-8 gap-2 items-center text-primary")}
          >
            <label>0%</label>
            <input
              type="range"
              name=""
              id=""
              step="5"
              min="0"
              max={MAX}
              value={seuilDePerte}
              className="w-full slider"
              onChange={({ currentTarget, target: { value } }) => {
                setSeuilDePerte(+value);
                setShowAssurance(parseInt(value) > 0 && true);
                wrapper.setTcVars({
                  evt_action_libelle: "SAC_simuler_perte",
                  evt_assurance: seuilDePerte,
                });
                wrapper.triggerEvent("actionUtilisateur", currentTarget, {
                  evt_action_libelle: "SAC_simuler_perte",
                });
              }}
              style={getBackgroundSize()}
            />
            <label>100%</label>
          </div>

          <div className={clsx("hidden", showAssurance && "block")}>
            <div
              className={
                "flex flex-col md:flex-row justify-between items-center"
              }
            >
              <h2 className="text-16 font-openSansSemiBold  text-primary uppercase">
                Choisissez le niveau de franchise de votre assurance
                <span className="text-red-600">*</span>&nbsp;:
              </h2>
              <div className="flex flex-col md:flex-row justify-between md:gap-8">
                {category.niveauxFranchise.map(
                  (percent: number, key: Key | null | undefined) => (
                    <button
                      key={key}
                      className={clsx(
                        "p-1 px-4 border-1 border-primary-800 rounded-3 text-center bg-white text-primary-600 sm:text-14",
                        percent === niveauDeFranchise
                          ? "bg-primary-600 text-white "
                          : ""
                      )}
                      onClick={(e) => handleNiveauDeFranchise(e, percent)}
                    >
                      {percent}%
                    </button>
                  )
                )}
              </div>
            </div>
            {!toggleResultat ? (
              <Button
                children="Lancer ma simulation"
                className="m-auto mt-8 uppercase transform transition duration-500 hover:bg-primary-800 hover:scale-110 border-0 rounded-sm"
                onClick={handleResultat}
              />
            ) : (
              <Typo
                variant="h5"
                className="sm:text-16 mt-10 text-center text-primary-800 pb-8"
                noMargin
              >
                Vous pouvez simuler en direct plusieurs niveaux de perte ou de
                franchise.
              </Typo>
            )}
          </div>
        </div>
        {toggleResultat && (
          <Resultat
            seuilDePerte={seuilDePerte}
            niveauDeFranchise={niveauDeFranchise}
            category={category}
          />
        )}
      </div>
    </div>
  );
};
