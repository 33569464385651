export interface SecteursDefaultState {
  surface?: number;
  capital?: number;
  prixMoyen?: number;
  rendementMoyen?: number;
}
export interface Secteurs {
  id: number;
  type: string;
  icon: string;
  description: string;
  example?: string;
  surface: string;
  capital: string;
  _surface?: string;
  _capital?: string;
  _rendementMoyen?: string;
  _prixMoyen?: string;
  total: number;
  jauge: number;
  rendementMoyen: string;
  prixMoyen: string;
  defaultState: SecteursDefaultState;
  niveauxFranchise: number[];
}
export const secteurs: Secteurs[] = [
  {
    id: 1,
    type: "Grandes cultures",
    icon: "culture",
    description: "en prenant l’exemple d'une nature de vos récoltes",
    example: "(blé, colza, maïs…)",
    surface: "100",
    jauge: 50,
    _surface: "HA",
    _rendementMoyen: "T/HA",
    _prixMoyen: "€/T",
    _capital: "€/HA",
    rendementMoyen: "8",
    prixMoyen: "230",
    capital: "800",
    total: 0,
    defaultState: {
      surface: 100,
      rendementMoyen: 8,
      prixMoyen: 230,
    },
    niveauxFranchise: [10, 15, 20, 25, 30],
  },
  {
    id: 2,
    type: "Viticulture",
    icon: "viticulture",
    description: "en prenant l’exemple d’une appellation",
    example: "(aoc, igp…)",
    jauge: 50,
    surface: "10",
    rendementMoyen: "40",
    prixMoyen: "200",
    capital: "800",
    _surface: "HA",
    _rendementMoyen: "HL/HA",
    _prixMoyen: "€/HL",
    _capital: "€/HA",
    total: 0,
    defaultState: {
      surface: 10,
      rendementMoyen: 40,
      prixMoyen: 200,
    },
    niveauxFranchise: [10, 15, 20, 25, 30, 40],
  },
  {
    id: 3,
    type: "Arboriculture",
    icon: "arboriculture",
    description: "en prenant l’exemple d'une nature de vos récoltes",
    example: "(abricot, pomme…)",
    jauge: 30,
    surface: "10",
    rendementMoyen: "9",
    prixMoyen: "2 200",
    capital: "800",
    _surface: "HA",
    _rendementMoyen: "T/HA",
    _prixMoyen: "€/T",
    _capital: "€/HA",
    total: 0,
    defaultState: {
      surface: 10,
      rendementMoyen: 9,
      prixMoyen: 2200,
    },
    niveauxFranchise: [15, 20, 25],
  },
  {
    id: 4,
    type: "Prairies",
    icon: "prairie",
    description: "",
    jauge: 30,
    surface: "100",
    capital: "800",
    rendementMoyen: "",
    prixMoyen: "",
    _surface: "HA",
    _capital: "€/HA",
    total: 0,
    defaultState: {
      surface: 100,
      capital: 800,
    },
    niveauxFranchise: [10, 15, 20],
  },
  {
    id: 5,
    type: "Légumes",
    icon: "legumes",
    description: "en prenant l’exemple d'une nature de vos récoltes",
    example: "(choux-fleurs, carottes, pois secs...)",
    jauge: 50,
    surface: "11",
    rendementMoyen: "50,6",
    prixMoyen: "43",
    capital: "800",
    _surface: "HA",
    _rendementMoyen: "T/HA",
    _prixMoyen: "€/T",
    _capital: "€/HA",
    total: 0,
    defaultState: {
      surface: 11,
      rendementMoyen: 50.6,
      prixMoyen: 43,
    },
    niveauxFranchise: [10, 15, 20, 25, 30],
  },
];
